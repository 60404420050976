const listaPuestos = [
  "Presidente",
  "Vicepresidente",
  "Tesorero",
  "Secretario",
  "Vocal 1",
  "Vocal 2",
  "Vocal 3",
  "Fiscal 1",
  "Fiscal 2",
  "Fiscal 3",
];

export default listaPuestos;